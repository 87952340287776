<template>
	<div>
		<v-btn color="orange darken-2" class="float-left mx-1" elevation="10" bottom rounded raised large @click="creationMenu = true">
			<v-icon class="mr-2"> mdi-plus </v-icon>{{ $t('explorer.createFolder') }}
		</v-btn>

		<v-dialog v-model="creationMenu" persistent width="500">
			<v-card rounded="xl">
				<v-card-title>
					{{ $t('explorer.createFolder') }}
					<v-spacer />
					<v-btn icon @click="creationMenu = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-text-field v-model="name" :label="$t('auth.name')" rounded hide-details="auto" class="pb-3" :rules="rules.name" required outlined />
				</v-card-text>

				<v-card-actions>
					<v-spacer />
					<v-btn color="secondary" text rounded @click="cancelCreation()">
						{{ $t('offers.cancel') }}
					</v-btn>
					<v-btn color="primary" :loading="loading" :disabled="loading" text rounded @click="callCreateFolder()">
						{{ $t('settings.upload') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'ExplorerCreateMenu',
	data() {
		return {
			creationMenu: false,
			name: '',
			rules: {
				name: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.nameMaxLength) || this.$t('rules.length', { length: this.nameMaxLength }),
				],
			},
		}
	},
	computed: {
		nameMaxLength() {
			return 32
		},
		...mapGetters({
			course: 'courses/course',
			loading: 'explorer/loading',
			foldersHistory: 'explorer/foldersHistory',
		}),
	},
	methods: {
		cancelCreation() {
			this.name = ''
			this.creationMenu = false
		},
		callCreateFolder() {
			this.createFolder({ name: this.name }).then(({ success }) => {
				if (success) {
					this.name = ''
					this.fetchFolder({
						course: this.course,
						folder: { name: this.foldersHistory[this.foldersHistory.length - 1].name, id: this.foldersHistory[this.foldersHistory.length - 1].id },
					})
				}
				this.creationMenu = false
			})
		},
		...mapActions('explorer', ['createFolder', 'fetchFolder']),
	},
}
</script>
